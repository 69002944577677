/**
 * @file Component that contains other components below the title and chart details
 * @author tpederson
 */
/* eslint-disable no-unused-vars */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { ChartContext, ThumbnailViewer, Toolbar, Viewer } from "@optum-ai-charts/chart-components";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { IndexStatus, useSearchContext } from "../../providers/search";
import ChartDetails from "../chart-details/chart-details";
import ChartNavigator from "../chart-navigator/chart-navigator";
import ChartSearch from "../chart-search/chart-search";
import { ErrorBoundary } from "../helpers";
import KeyboardSupport from "../keyboard-support/keyboard-support";

import { HedisDispatcher, MEMBER_MATCH_STATUS, useHedisContext } from "../../providers/hedis";

// styles
import { ErrorIcon, FullscreenExitIcon, FullscreenIcon } from "@uitk/react-icons";
import queryString from "query-string";
import "./main.scss";

const AutoPageReview = React.lazy(() => import("../member-match/auto-page-review"));
const MemberVerifier = React.lazy(() => import("../member-match/member-verifier"));
const AutoMemberMatchSearch = React.lazy(() => import("../member-match/auto-mm-search"));
const UnknownPagesPreview = React.lazy(() => import("../member-match/unknown-pages-preview"));

export default function Main(): React.JSX.Element {
  // @ts-ignore
  const { pageCurrent, thumbnail_open } = useContext(ChartContext);
  const { measure, memberMatchStatus, suspiciousPages } = useHedisContext();
  const dispatch = useContext(HedisDispatcher);
  const [showNavigator, setShowNavigator] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showMatchAlert, setShowMatchAlert] = useState(true);
  const [memberIsConfirmed, setMemberIsConfirmed] = useState(false);
  const { indexStatus } = useSearchContext();
  const [showSearchAlert, setShowSearchAlert] = useState(false);
  const [isThumbnailOpenedOnce, setIsThumbnailOpenedOnce] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPoppedout, setIsPoppedout] = useState(false);

  const toggleSidebar = (): void => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleNavigator = (): void => {
    setShowNavigator(!showNavigator);
  };

  const handleUnknownPageCLick = (): void => {
    setShowNavigator(!showNavigator);
    setIsSidebarOpen(false);
  };

  const handleSearchOpen = (): void => {
    setIsSidebarOpen(false);
    setShowNavigator(true);
  };

  useEffect(() => {
    suspiciousPages && suspiciousPages.length && setIsSidebarOpen(false);
  }, [suspiciousPages]);

  const handlePopout = (): void => {
    const url = window.location.href;
    setIsFullscreen(!isFullscreen);
    const child: any = window.open(
      `${url}&popout=true&mmStatus=${memberMatchStatus}`,
      "",
      "toolbar=0,status=0,width=626,height=436",
    );

    //window.onBeforeUnload
    child.onunload = (): void => {
      setTimeout(function () {
        if (child.closed) {
          setIsFullscreen(isFullscreen);
        }
      }, 300);
    };
  };

  const handlePopin = (): void => {
    window.close();
  };

  useEffect(() => {
    setShowNavigator(memberMatchStatus === MEMBER_MATCH_STATUS.CONFIRMED);
  }, [memberMatchStatus]);

  useEffect(() => {
    // effect to set a timeout to hide the green confirmed banner after the member match has been confirmed
    const confirmed = memberMatchStatus === MEMBER_MATCH_STATUS.CONFIRMED;
    if (confirmed) {
      setTimeout(() => setShowMatchAlert(false), 1000 * 10);
    }
    setMemberIsConfirmed(confirmed);
  }, [memberMatchStatus]);

  useEffect(() => {
    if (indexStatus === IndexStatus.ERROR) {
      setShowSearchAlert(true);
      setTimeout(() => setShowSearchAlert(false), 1000 * 4);
    }
  }, [indexStatus]);

  useEffect(() => {
    thumbnail_open && setIsThumbnailOpenedOnce(true);
  }, [thumbnail_open]);

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const popout = params?.popout;
    const mmStatus = Number(params?.mmStatus);

    if (popout) {
      setIsPoppedout(true);
    }

    if (mmStatus && mmStatus === MEMBER_MATCH_STATUS.CONFIRMED) {
      // dispatch(confirmMemberMatch())
    }
  }, []);

  return (
    <main role="presentation" tabIndex={0}>
      <>
        <div className={isFullscreen ? "fullscreen-text" : "f-none"}>Work item image is in separate window</div>
        <section className={isFullscreen ? "f-none" : "chart-container"}>
          {/* <ChartDetails /> */}
          <ErrorBoundary>
            <div className="chart-wrapper">
              {suspiciousPages && suspiciousPages.length > 0 && memberIsConfirmed && showMatchAlert && (
                <Alert className="chart-details-info" severity="success" variant="filled" sx={{ borderRadius: 0 }}>
                  <div className="status-text-large">
                    <span>Member Detail:</span>
                    <span className="member-status"> confirmed</span>
                  </div>
                </Alert>
              )}
              {suspiciousPages && suspiciousPages.length > 0 && !memberIsConfirmed && (
                <Alert
                  className="chart-details-info"
                  icon={<ErrorIcon height={20} width={20} fill="#C40000" />}
                  severity="error"
                  variant="filled"
                  sx={{ borderRadius: 0, backgroundColor: "#FCF0F0" }}
                >
                  <div className="status-text-large">
                    <span>Suspicious pages. Please ensure member is verified.</span>
                  </div>
                </Alert>
              )}
              {indexStatus === IndexStatus.ERROR && showSearchAlert && (
                <Alert className="chart-details-info" severity="error" variant="filled" sx={{ borderRadius: 0 }}>
                  <div className="status-text-large">
                    <span>Search is not available for this chart. Please continue abstraction.</span>
                  </div>
                </Alert>
              )}
              <div className="toolbar-container">
                <Toolbar style={{ width: "550px" }} />
                <ChartSearch openSearchResults={handleSearchOpen} />
                <button
                  className="full-screen-btn"
                  onClick={isPoppedout ? handlePopin : handlePopout}
                  style={{ cursor: "pointer", visibility: "hidden" }}
                  disabled
                >
                  {isPoppedout ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </button>
              </div>
              <div style={{ position: "relative" }}>
                <ChartDetails toggleSidebar={handleUnknownPageCLick} />
                <div className="sliding-sidebar-container">
                  <div className={`toggle-btn-sidebar ${!isSidebarOpen ? "open" : ""}`}>
                    <button
                      className="toggle-arrow-btn"
                      style={{
                        width: "20px",
                        height: "35px",
                        border: "2px solid #3f3ff3",
                        marginTop: "10px",
                      }}
                      onClick={toggleSidebar}
                    >
                      {!isSidebarOpen ? "" : <ArrowBackIcon />}
                    </button>
                  </div>
                  <div className={`sidebar-content ${!isSidebarOpen ? "open" : "none"}`}>
                    {showNavigator && (
                      <>
                        <ChartNavigator closeSidebar={toggleSidebar} measure={measure || "APH"} />
                      </>
                    )}
                    {!showNavigator && memberMatchStatus === MEMBER_MATCH_STATUS.CONFIRMED && (
                      <Suspense fallback={<div></div>}>
                        <UnknownPagesPreview toggleNavigator={toggleNavigator} closeSidebar={toggleSidebar} />
                        <AutoPageReview
                          duration={parseInt(process.env.REACT_APP_AUTO_PAGE_REVIEW_MS || "3000")}
                          pageCurrent={pageCurrent}
                          hedisDispatch={dispatch}
                          type="unknown"
                        />
                      </Suspense>
                    )}
                    {memberMatchStatus !== MEMBER_MATCH_STATUS.CONFIRMED && (
                      <Suspense fallback={<div></div>}>
                        <MemberVerifier openSidebar={toggleSidebar} closeSidebar={toggleSidebar} />
                        <AutoMemberMatchSearch />
                        <AutoPageReview
                          duration={parseInt(process.env.REACT_APP_AUTO_PAGE_REVIEW_MS || "3000")}
                          pageCurrent={pageCurrent}
                          hedisDispatch={dispatch}
                          type="suspicious"
                        />
                      </Suspense>
                    )}
                  </div>
                </div>
              </div>

              <Viewer>
                <CircularProgress
                  className="chart-page-load"
                  size={80}
                  style={{
                    opacity: 0.3,
                    color: "#4cc6ff",
                  }}
                />
              </Viewer>
              {thumbnail_open || isThumbnailOpenedOnce ? (
                <div className={thumbnail_open ? "" : "dnone"}>
                  <ThumbnailViewer>
                    <CircularProgress
                      className="chart-page-load"
                      size={80}
                      style={{
                        opacity: 0.3,
                        color: "#4cc6ff",
                      }}
                    />
                  </ThumbnailViewer>{" "}
                </div>
              ) : (
                ""
              )}
            </div>

            <KeyboardSupport />
          </ErrorBoundary>
        </section>
      </>
    </main>
  );
}
