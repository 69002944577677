/**
 * @file Component that contains other components below the title and chart details
 * @author tpederson
 */
/* eslint-disable no-unused-vars */
import React from "react";
import { CircularProgress } from "@mui/material";
import { Viewer, Toolbar } from "@optum-ai-charts/chart-components";
import ChartSearch from "../chart-search/chart-search";
import { ErrorBoundary } from "../helpers";
import KeyboardSupport from "../keyboard-support/keyboard-support";

// styles
import "./viewer-main.scss";

export default function Main(): React.JSX.Element {
  return (
    <main role="presentation" tabIndex={0}>
      <>
        <section className="chart-container">
          <ErrorBoundary>
            <div className="chart-wrapper">
              <div className="toolbar-container">
                <Toolbar style={{ width: "550px" }} />
                <ChartSearch openSearchResults={() => {}} />
              </div>
              <Viewer>
                <CircularProgress
                  className="chart-page-load"
                  size={80}
                  style={{
                    opacity: 0.3,
                    color: "#4cc6ff",
                  }}
                />
              </Viewer>
            </div>
            <KeyboardSupport />
          </ErrorBoundary>
        </section>
      </>
    </main>
  );
}
